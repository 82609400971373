/* 
  Code generated with generateTheme.ts file. DO NOT EDIT. 
  last updated at 2025-01-03T10:40:23.172Z */
module.exports.colors = {
  "branding": {
    "blue": "var(--branding-blue)",
    "purple": "var(--branding-purple)",
    "pink": "var(--branding-pink)"
  },
  "interactive": {
    "positive": "var(--interactive-positive)",
    "positive-highlight": "var(--interactive-positive-highlight)",
    "destructive": "var(--interactive-destructive)",
    "destructive-highlight": "var(--interactive-destructive-highlight)",
    "disabled": "var(--interactive-disabled)"
  },
  "text": {
    "primary": "var(--text-primary)",
    "secondary": "var(--text-secondary)",
    "disabled": "var(--text-disabled)",
    "links": "var(--text-links)"
  },
  "dataviz": {
    "1": "var(--dataviz-1)",
    "2": "var(--dataviz-2)",
    "3": "var(--dataviz-3)",
    "4": "var(--dataviz-4)",
    "5": "var(--dataviz-5)",
    "6": "var(--dataviz-6)",
    "7": "var(--dataviz-7)",
    "8": "var(--dataviz-8)"
  },
  "linea": {
    "primary": "var(--linea-primary)",
    "background": "var(--linea-background)"
  },
  "background": {
    "base0": "var(--background-base0)",
    "base1": "var(--background-base1)",
    "base2": "var(--background-base2)",
    "base3": "var(--background-base3)",
    "idle": "var(--background-idle)",
    "active": "var(--background-active)",
    "destructive-idle": "var(--background-destructive-idle)",
    "destructive-active": "var(--background-destructive-active)",
    "shadow": "var(--background-shadow)"
  },
  "outlines": {
    "1": "var(--outlines-1)",
    "2": "var(--outlines-2)",
    "3": "var(--outlines-3)",
    "diabled": "var(--outlines-diabled)",
    "focus": "var(--outlines-focus)"
  },
  "accent": {
    "primary": "var(--accent-primary)",
    "background": "var(--accent-background)"
  },
  "severity": {
    "critical": "var(--severity-critical)",
    "high": "var(--severity-high)",
    "medium": "var(--severity-medium)",
    "low": "var(--severity-low)",
    "informational": "var(--severity-informational)"
  },
  "semantic": {
    "success-primary": "var(--semantic-success-primary)",
    "success-background": "var(--semantic-success-background)",
    "error-primary": "var(--semantic-error-primary)",
    "error-background": "var(--semantic-error-background)",
    "warning-primary": "var(--semantic-warning-primary)",
    "warning-background": "var(--semantic-warning-background)",
    "informational-primary": "var(--semantic-informational-primary)",
    "informational-background": "var(--semantic-informational-background)"
  },
  "backdrops": {
    "1": "var(--backdrops-1)"
  }
}
 
 module.exports.spacing = {
  "xxs": "var(--spacing-xxs)",
  "xs": "var(--spacing-xs)",
  "s": "var(--spacing-s)",
  "m": "var(--spacing-m)",
  "l": "var(--spacing-l)",
  "xl": "var(--spacing-xl)",
  "2xl": "var(--spacing-2xl)",
  "3xl": "var(--spacing-3xl)",
  "4xl": "var(--spacing-4xl)"
}
 
 module.exports.borderRadius = {
  "xs": "var(--border-radius-xs)",
  "sm": "var(--border-radius-sm)",
  "md": "var(--border-radius-md)",
  "lg": "var(--border-radius-lg)"
}
 
 module.exports.borderWidth = {
  "large": "var(--border-width-large)",
  "medium": "var(--border-width-medium)",
  "small": "var(--border-width-small)"
}
 
 module.exports.fontSize = {
  "s": "var(--font-size-s)",
  "m": "var(--font-size-m)",
  "l": "var(--font-size-l)",
  "xl": "var(--font-size-xl)",
  "xxl": "var(--font-size-xxl)",
  "4xl": "var(--font-size-4xl)"
}
 
 module.exports.lineHeight = {
  "s": "var(--line-size-s)",
  "m": "var(--line-size-m)",
  "l": "var(--line-size-l)",
  "xl": "var(--line-size-xl)",
  "xxl": "var(--line-size-xxl)"
}
 
 module.exports.outlineWidth = {
  "xs": "var(--outline-size-xs)",
  "sm": "var(--outline-size-sm)",
  "md": "var(--outline-size-md)",
  "lg": "var(--outline-size-lg)",
  "xl": "var(--outline-size-xl)"
}
 
 module.exports.boxShadow = {
  "shadow1": "var(--box-shadow-shadow1)",
  "shadow2": "var(--box-shadow-shadow2)",
  "shadow3": "var(--box-shadow-shadow3)",
  "shadow4": "var(--box-shadow-shadow4)"
}