import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { difference, isEqual } from "lodash";
import { DEFAULT_DATE_FORMAT } from "shared";
import { colors, fontSize, spacing } from "ds-ui";
import {
  DataGridProProps,
  GridRowId,
  useGridApiRef,
  GridRowSelectionModel as GridRowSelectionModelInternal,
  GridColumnResizeParams,
  GridFilterModel,
  GridPinnedColumns,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
  GRID_CHECKBOX_SELECTION_FIELD,
  GridLoadingOverlay,
  gridClasses,
} from "@mui/x-data-grid-pro";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import { useGrid } from "./useGrid";
import { GridToolbar, StyledDataGridPro } from "./organisms";
import { GridNoRowsOverlay, GridToolbarButton } from "./atoms";
import { GridColumnMenu } from "./molecules";
import { columnOrderWithoutFixed } from "./utils";
import { mapFiltersToMuiFilterModel, mapMuiFilterModelToFilters } from "./filters";
import { GridRowSelectionModel } from "./types";
import { normalizeColumnDefinitions } from "./normalizeColumnDefinitions";
import { CHGridLoadingOverlay } from "./CHGridLoadingOverlay";

interface GridProps extends Omit<DataGridProProps, "columns" | "components" | "componentsProps"> {
  onGetMoreRows?: () => Promise<{ newRows: Array<{ id: GridRowId }> | null }>;
  getDetailPanelContent?: (row: any) => React.ReactNode;
  autoExpandSingleRow?: boolean;
  messages?: {
    item?: string;
    itemPlural?: string;
    searchPlaceholder?: string;
  };
  renderToolbarActions?: (selection: GridRowSelectionModel) => React.ReactNode;
  hasMoreRows?: boolean;
  totalRowsCount?: number;
  showTotalRowsCount?: boolean;
  canPickRows?: boolean;
  onRowPicked?: (id: GridRowId | undefined) => any;
  onRefresh?: () => any;
  dateFormat?: string;
  timezone?: string;
  searchEnabled?: boolean;
  noRowsTitle?: string;
  noRowsDescription?: string;
  alignTop?: boolean;
  hideActions?: boolean;
  hideFilterLogicOperator?: boolean;
  customSx?: (style: SxProps<Theme>) => SxProps<Theme>;
  disableSelectAll?: boolean;
  designSystemStyles?: boolean;
}

/**
 * Because we ignore empty filters, we should change the MUI filter model only when
 * the filters we care about are different than the ones in MUI.
 * This function is used to determine if we should change the MUI filter model.
 */
export const areNewFiltersIncludedInMuiFilters = (
  newFilters: GridFilterModel,
  muiFilters: GridFilterModel
): boolean => {
  if (newFilters.logicOperator !== muiFilters.logicOperator) {
    return false;
  }

  if (newFilters.items.length > muiFilters.items.length) {
    // We added new filters.
    // Usually we should have as many or less fields than MUI, as we ignore empty filters.
    return false;
  }

  if (newFilters.items.length === muiFilters.items.length) {
    // When we have the same number of filters they should be identical.
    return isEqual(newFilters, muiFilters);
  }

  // If we got here it means that either MUI has some empty filters we ignored, or we removed filters.
  // Here we verify that the fields we care about are in MUI filters.
  const verifiedFields = new Set();
  for (const filter of newFilters.items) {
    const muiFilter = muiFilters.items.find((muiF) => muiF.field === filter.field);

    if (!isEqual(filter, muiFilter)) {
      return false;
    }

    verifiedFields.add(filter.field);
  }

  // All of our filters matched, now we need to make sure that everything else in MUI is empty.
  for (const muiFilter of muiFilters.items) {
    if (!verifiedFields.has(muiFilter.field) && muiFilter.value) {
      return false;
    }
  }

  return true;
};

const newDesignSystemStyles = (style: any, isExpandable = false) => {
  return {
    ...style,

    '& .rowDisabled .MuiDataGrid-cell:not([data-field="__detail_panel_toggle__"]):not([data-field="__check__"])':
      {
        "&>*": {
          opacity: 0.5,
        },
      },
    "& .MuiDataGrid-cell": {
      ...style["& .MuiDataGrid-cell"],
      "backgroundColor": `${colors.background.base2}`,
      "color": colors.text.primary,
      "fontSize": fontSize.m,
      "cursor": "auto",
      "transition": "none !important",
      "borderColor": `${colors.outlines[1]} !important`,
      "borderStyle": "solid",
      "borderRightWidth": "0px !important",
      "borderLeftWidth": "0px !important",

      "&:first-child": {
        borderRadius: "4px 0 0 4px",
        borderLeftWidth: "1px !important",
      },
      "&:last-child": {
        borderRadius: "0 4px 4px 0",
        borderRightWidth: "1px !important",
      },
      [`&:not([data-field="__detail_panel_toggle__"]):not([data-field="__check__"])`]: {
        paddingLeft: isExpandable ? "48px" : "48px",
      },
    },
    "& .MuiDataGrid-virtualScrollerRenderZone": {
      ...style["& .MuiDataGrid-virtualScrollerRenderZone"],
      maxWidth: "100%",
    },
    "& .MuiDataGrid-cellContent": {
      ...style["& .MuiDataGrid-cellContent"],
      color: colors.text.secondary,
      fontSize: fontSize.m,
    },
    "& .MuiDataGrid-columnHeaders": {
      ...style["& .MuiDataGrid-columnHeaders"],
      backgroundColor: colors.background.base3,
      color: colors.text.secondary,
      fontSize: fontSize.l,
      border: `1px solid ${colors.outlines[1]}`,
      borderRadius: 4,
      [`& .${gridClasses.columnHeadersInner} .${gridClasses.columnHeader}:first-child`]: {
        marginLeft: isExpandable ? "0px" : "20px",
      },
    },
    "& .MuiDataGrid-row": {
      ...style["& .MuiDataGrid-row"],
      margin: "8px 0 0 0 !important",

      maxWidth: "100%",
      transition: "none !important",
    },

    "& .MuiDataGrid-virtualScroller .MuiDataGrid-row.Mui-hovered .MuiDataGrid-cell": {
      ...style["& .MuiDataGrid-virtualScroller .MuiDataGrid-row.Mui-hovered .MuiDataGrid-cell"],
      backgroundColor: `${colors.background.base3} !important`,
    },
    "& .MuiDataGrid-virtualScroller .MuiDataGrid-row.Mui-hovered": {
      ...style["& .MuiDataGrid-virtualScroller .MuiDataGrid-row.Mui-hovered"],
      backgroundColor: `${colors.background.base3} !important`,
      cursor: "auto",
    },
    "& .MuiDataGrid-columnHeader": {
      ...style["& .MuiDataGrid-columnHeader"],
      backgroundColor: colors.background.base3,
      color: colors.text.secondary,
      fontSize: fontSize.m,
      paddingTop: spacing.l,
      paddingBottom: spacing.l,
      borderColor: `${colors.background.base2} !important`,

      [`&.${gridClasses.columnHeaderCheckbox}, & .${gridClasses.cellCheckbox}`]: {
        borderRight: "auto",
      },
    },
    [`& .${gridClasses.virtualScrollerContent} .${gridClasses["pinnedColumns--right"]}`]: {
      ...style[`& .${gridClasses.virtualScrollerContent} .${gridClasses["pinnedColumns--right"]}`],
      [`&.Mui-hovered, &.Mui-hovered .${gridClasses.cell}, &.picked .${gridClasses.cell}`]: {
        backgroundColor: `${colors.background.base3} !important`,
      },
      [".MuiDataGrid-row"]: {
        boxShadow: "none",
      },
    },
    [`& .${gridClasses.cell}[data-field="__check__"]`]: {
      paddingLeft: isExpandable ? "0px" : "20px",
    },
    [`& .${gridClasses["pinnedColumnHeaders--right"]}`]: {
      ...style[`& .${gridClasses.pinnedColumns}`],
      backgroundColor: `${colors.background.base3} !important`,
      color: colors.text.secondary,
      fontSize: fontSize.m,
    },
    [`& .${gridClasses.virtualScrollerContent} .${gridClasses.pinnedColumns}`]: {
      [`& .${gridClasses.row}`]: {
        boxShadow: "none",
        [`&.${gridClasses["row--lastVisible"]}`]: {
          boxShadow: "none",
        },
      },
    },
  };
};

export function CHDataGrid({
  onGetMoreRows,
  hasMoreRows,
  rows,
  totalRowsCount,
  showTotalRowsCount,
  renderToolbarActions,
  messages = {},
  canPickRows,
  dateFormat = DEFAULT_DATE_FORMAT,
  timezone,
  onRowPicked,
  onRefresh,
  getDetailPanelContent,
  searchEnabled,
  noRowsTitle,
  noRowsDescription,
  onRowClick,
  hideActions,
  hideFilterLogicOperator,
  slots,
  disableSelectAll,
  autoExpandSingleRow = true,
  customSx,
  designSystemStyles = false,
  ...restProps
}: GridProps) {
  const gridState = useGrid();
  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = React.useState<GridRowId[]>([]);
  const gridStateRef = useRef(gridState);
  gridStateRef.current = gridState;

  const {
    columnVisibilityModel: storedColumnVisibilityModel,
    setColumnVisibilityModel,
    search,
    sortModel,
    setSortModel,
    pinnedColumns,
    setPinnedColumns,
    columnsOrder: storedColumnsOrder,
    columnsWidths,
    setColumnsWidths,
    selectionModel,
    setSelectionModel,
    pickedRow,
    setPickedRow,
    density,
    filters,
    setFilters,
    columns,
    resetGrid,
  } = gridState;

  const columnVisibilityModel = {
    ...storedColumnVisibilityModel,
    [GRID_DETAIL_PANEL_TOGGLE_FIELD]: !!getDetailPanelContent,
    [GRID_CHECKBOX_SELECTION_FIELD]: !!restProps.checkboxSelection,
  };

  const columnsOrder = columnOrderWithoutFixed(storedColumnsOrder);

  const apiRef = useGridApiRef();

  useEffect(() => {
    if (apiRef.current) {
      return apiRef.current.subscribeEvent("columnHeaderDragEnd", () => {
        const state = apiRef.current.exportState();
        gridStateRef.current.setColumnsOrder(state.columns!.orderedFields!);
      });
    }
    return;
  }, [apiRef.current]);

  useEffect(() => {
    (window as any).resetGrid = () => gridStateRef.current.resetGrid();

    return () => {
      delete (window as any)["resetGrid"];
    };
  }, []);

  const normalizedColumns = useMemo(
    () =>
      normalizeColumnDefinitions(columns, {
        dateFormat,
        timezone,
        hideActions,
        disableSelectAll,
        newStyle: designSystemStyles,
      }),
    [columns, dateFormat, timezone]
  );

  // Actions column should always be pinned
  const normalizedPinnedColumns = useMemo(() => {
    if (pinnedColumns.right?.includes("actions") || hideActions) {
      return pinnedColumns;
    }

    return {
      left: [...(pinnedColumns?.left || [])],
      right: [...(pinnedColumns?.right || []), "actions"],
    };
  }, [pinnedColumns]);

  const onPinnedColumnsChange = useCallback(
    (newPinnedColumns: GridPinnedColumns) => {
      const right = (newPinnedColumns?.right || []).filter((col: any) => col !== "actions");
      const left = newPinnedColumns?.left || [];
      setPinnedColumns({ right, left });
    },
    [pinnedColumns, columnsOrder]
  );

  // Convert our generic filter model to specific mui data grid model
  const [filterModel, setFilterModel] = useState(() => mapFiltersToMuiFilterModel(filters));

  const onFilterModelChange = useCallback(
    (filterModel: GridFilterModel) => {
      setFilterModel(filterModel);

      const newFilters = mapMuiFilterModelToFilters(filterModel, normalizedColumns);
      if (!isEqual(filters, newFilters)) {
        setFilters(newFilters);
      }
    },
    [filters, normalizedColumns]
  );

  useEffect(() => {
    const newFilterModel = mapFiltersToMuiFilterModel(filters);
    if (!areNewFiltersIncludedInMuiFilters(newFilterModel, filterModel)) {
      setFilterModel(newFilterModel);
    }
  }, [filters]);

  const onColumnsWidthChange = useCallback(
    ({ colDef, width }: GridColumnResizeParams) => {
      setColumnsWidths({
        ...columnsWidths,
        [colDef.field]: width,
      });
    },
    [columnsWidths]
  );

  const onSelectionModelChange = useCallback(
    (selected: GridRowSelectionModelInternal) => {
      const rowsCount = rows.length;
      const areSomeSelected = selected.length > 0;
      const isAllSelected = selected.length === rowsCount;
      const wasAllSelected = selectionModel.all;

      // When we click select on single item when all selected, then we invert logic and make it the only selected item
      if (wasAllSelected && !isAllSelected && areSomeSelected) {
        const excluded = difference(selectionModel.selected, selected);

        setSelectionModel({ selected: excluded, all: false });
      } else {
        setSelectionModel({ selected: selected, all: isAllSelected });
      }
    },
    [rows, selectionModel]
  );

  const handleOnRowsScrollEnd = async () => {
    if (!hasMoreRows || !onGetMoreRows) {
      return;
    }

    const { newRows } = await onGetMoreRows();
    // If "All" are selected then add new items to selected array
    if (newRows && selectionModel.all) {
      setSelectionModel({
        ...selectionModel,
        selected: [...selectionModel.selected, ...newRows.map((row) => row.id)],
      });
    }
  };

  const handleRowClick = (rowId: GridRowId) => {
    if (canPickRows) {
      if (rowId !== pickedRow) {
        setPickedRow(rowId);
        onRowPicked?.(rowId);
      }
    }
    if (getDetailPanelContent) {
      setDetailPanelExpandedRowIds((prev) => {
        if (prev.includes(rowId)) {
          return prev.filter((id) => id !== rowId);
        } else {
          return [...prev, rowId];
        }
      });
    }
  };

  const memoDetailPanelContent = useCallback((...args: any) => getDetailPanelContent?.(args), []);

  // Auto expand detail panel if there is only one row
  useEffect(() => {
    if (autoExpandSingleRow && getDetailPanelContent && rows.length === 1) {
      setDetailPanelExpandedRowIds([rows[0].id]);
    }
  }, [rows.length, getDetailPanelContent, autoExpandSingleRow]);

  return (
    <StyledDataGridPro
      apiRef={apiRef}
      rows={rows}
      columns={normalizedColumns}
      initialState={{
        columns: {
          orderedFields: columnsOrder,
          columnVisibilityModel,
        },
        sorting: {
          sortModel,
        },
        filter: {
          filterModel,
        },
      }}
      columnHeaderHeight={designSystemStyles ? 52 : 34}
      rowHeight={designSystemStyles ? 54 : 44}
      sortingMode="server"
      filterMode="server"
      getRowSpacing={({ isFirstVisible }) => ({ top: isFirstVisible ? 4 : 2, bottom: 2 })}
      getRowClassName={({ id }) => (id === pickedRow ? "picked" : "")}
      hideFooter
      hideFilterLogicOperator={hideFilterLogicOperator}
      checkboxSelection
      disableRowSelectionOnClick
      showColumnVerticalBorder
      columnVisibilityModel={columnVisibilityModel}
      onColumnVisibilityModelChange={setColumnVisibilityModel}
      rowSelectionModel={selectionModel.selected}
      onRowSelectionModelChange={onSelectionModelChange}
      sortModel={sortModel}
      onSortModelChange={setSortModel}
      filterModel={filterModel}
      onFilterModelChange={onFilterModelChange}
      pinnedColumns={normalizedPinnedColumns}
      onPinnedColumnsChange={onPinnedColumnsChange}
      density={density}
      onColumnWidthChange={onColumnsWidthChange}
      onRowsScrollEnd={restProps.onRowsScrollEnd ?? handleOnRowsScrollEnd}
      onRowClick={(...args) => {
        handleRowClick(args[0].id);
        onRowClick?.(...args);
      }}
      customSx={designSystemStyles ? newDesignSystemStyles : customSx}
      getDetailPanelHeight={() => "auto"}
      detailPanelExpandedRowIds={detailPanelExpandedRowIds}
      slots={{
        toolbar: GridToolbar,
        columnMenu: GridColumnMenu,
        noRowsOverlay: GridNoRowsOverlay,
        baseButton: GridToolbarButton,
        detailPanelExpandIcon: KeyboardArrowRight,
        detailPanelCollapseIcon: KeyboardArrowDown,
        loadingOverlay: designSystemStyles ? CHGridLoadingOverlay : GridLoadingOverlay,
        ...slots,
      }}
      getDetailPanelContent={memoDetailPanelContent}
      isExpandable={!!getDetailPanelContent}
      onDetailPanelExpandedRowIdsChange={(ids) => setDetailPanelExpandedRowIds(ids)}
      slotProps={{
        toolbar: {
          totalRowsCount,
          showTotalRowsCount,
          selectionModel,
          renderToolbarActions,
          searchEnabled,
          messages,
        },

        noRowsOverlay: {
          // @ts-ignore
          hasActiveFilters: !!search.length,
          title: noRowsTitle,
          description: noRowsDescription,
        },
        columnsPanel: {
          onReset: resetGrid,
        },
      }}
      {...restProps}
    />
  );
}
